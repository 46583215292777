import { PATHS, PAYMENTS_FEE_CONST } from '@belong/common';

export const FAQS_STRINGS = {
  'meta.title': 'FAQs | All Your Belonging Questions Answered | Belong',
  'meta.description': `Considering Belong to manage your home? Hoping to live in a Belong home, but have questions? We've got answers!`,

  'page.title.1': 'FAQs',
  'page.title.2': 'Questions and\u00a0Answers',
  'page.subtitle': 'We are here to serve. If we missed a question you need an answer to, reach out to\u00a0us!',
  homeowner_heading: 'Homeowner',
  homeowner: [
    {
      q: 'How does Belong benefit homeowners?',
      a: [
        "When you become a Belong Member, you'll experience the ease of rental management done right. Imagine the benefits of local expertise from old school property managers paired with a seamless and constantly upgrading experience built by experts in technology and hospitality. That is\u00a0Belong.",
        "We take charge as soon as you hand us the keys, ensuring your home is well cared for. At every step of the way, we have aligned our team’s incentives to match yours as a homeowner, which means that we only make money when you do and that we can provide a truly world-class service. We offer you access to our cutting-edge online platform and mobile app, allowing you to approve requests, view financial statements, and receive payments right from your phone. It's all you need, in one place, accessible online with the peace of mind that our boots on the ground have you, your home, and your residents taken care\u00a0of.",
      ],
    },
    {
      q: 'Where does Belong offer its services?',
      a: [
        // 'At Belong we have two offerings, BelongPro for the hands off homeowner looking for end-end property management and BelongX for self\u00a0managers.',
        // 'BelongX is available nationwide.',
        `BelongPro is currently available in the major metro areas in multiple states. We have rapid expansion plans to bring the magic of Belong to more homeowners and residents across the country. To find out if your home is within our coverage area or receive notifications about service availability in your neighborhood, simply [enter your\u00a0address](${PATHS.HOMEOWNER_ONBOARDING_PROPERTY_ADDRESS}).`,
      ],
    },
    {
      q: 'What type of homes do you work with? Do you rent out apartments?',
      a: [
        'Belong specializes in managing residential rental homes, including apartments (condos), single-family homes, and multi-family homes. Whether you currently have residents or your home is vacant, we handle it all seamlessly, ensuring a hassle-free experience for you and your residents.',
      ],
    },
    {
      q: 'How much does it cost to use Belong? What are the fees I pay for your services?',
      a: [
        "Belong takes pride in offering [transparent pricing](/homeowners#pricing-table). We charge a one-time placement fee every time we place new residents in your home, and a monthly management fee each month you receive rent. Prices vary by market. Additionally, when we need to bring in specialists for maintenance or repairs, we'll price shop, leveraging one of the nation’s largest networks of maintenance professionals to ensure you get the best deal.",
        'Did you know that property management fees, including ours, are tax-deductible? In fact, all expenses paid to improve, manage, and maintain your property can be deducted from your taxes. We will make sure you have everything you need to deduct our fees!',
        'Learn more about our [pricing](/homeowners) or [speak with us](/faq?activeModal=CONTACT_US) directly; we would love to hear from you!',
      ],
    },
    {
      q: 'What services are included?',
      a: [
        'Belong offers a one-stop-shop for all your home management needs including:',
        '• Preparing your home for future residents by conducting an inspection and addressing maintenance and repairs.',
        '• Marketing and showcasing your home with professional photography, 3-D virtual walkthroughs, open houses, and self-serve tours. What sets us apart is that we offer these tours at no extra cost to homeowners. This flexibility ensures that we reach a wider pool of potential residents, making the process efficient and hassle-free.',
        '• Distributing the listing on 26 websites including Zillow, Apartments.com, Craigslist, Trulia, Zumper and Hotpads. Additionally, we list the home in its best light on our website that receives tens of thousands of visitors a month - a true competitive advantage.',
        '• Screening and placing residents through our thorough vetting and background check process. We are so confident in our qualification process that we guarantee your rental payments whether or not a resident pays us.',
        '• Managing the resident relationship, including rent collection, home visits, and fostering community connections. - you will not need to worry about dealing with Residents directly. Our Member Service Team will ensure both you and your Resident have everything needed.',
        '• Providing long-term care and handling maintenance, including 24/7 emergency service.',
        '• Access to our industry-leading products and services such as the mobile app so you can manage your home from the convenience of your phone, cash flow management products such as Split It to finance any major fees or maintenance expenses, all-in-one insurance etc.',
      ],
    },
    {
      q: 'Do you help me come up with a rent price that is right for the market?',
      a: [
        'At Belong, we use both an art and science to identifying your ideal listing price. We use the best data sources that consider the current trends, market, and real-time property value. We understand how local charms, features, and the community impact pricing. We are on the same team so we will make sure you get the optimal listing price for your home.',
      ],
    },
    {
      q: 'How fast do I get paid? When will you list my home?',
      a: [
        "The moment you become a Belong Homeowner, we get right to work. We'll send an inspector to your home, and put a plan in place. We'll provide you with a full inspection report and market pricing analysis, to ensure your home gets loved ASAP.",
      ],
    },
    {
      q: 'Do I have to work exclusively with Belong or can I list on multiple sites?',
      a: [
        'Belong includes a full-suite of property management services. You’ll work exclusively with us, and we’ll list your home on the top 26 different property rental websites. All included in our placement fee.',
      ],
    },
    {
      q: 'Can I rent out my home or apartment furnished?',
      a: [
        "The short answer is no, all Belong Homes come unfurnished. And we don't recommend trying to do that on your own either! While you probably have great taste, in general, furnished homes rent for way less than unfurnished homes, and lead to negative experiences down the road if something gets damaged.",
      ],
    },
    {
      q: 'What is your resident vetting process? How do you do it better than others?',
      a: [
        "Our mission is to find someone who will love your home as much as you do, so we don’t cut corners. Belong's resident vetting process is thorough and designed to provide homeowners with peace of mind. We conduct comprehensive background and credit checks, employment verification, reference checks, and personal interviews with our highly-trained resident specialists to ensure the reliability and financial stability of potential residents. Our approach is backed by advanced screening technology, allowing us to carefully select residents who are a great fit for your property. This sets us apart from competitors by prioritizing the safety and security of your investment.",
        'We are so confident in our qualification process that we guarantee your rental payments whether or not a resident pays us.',
      ],
    },
    {
      q: 'Does Belong handle evictions, including coverage against legal fees? How is it different compared to the eviction services of other property management companies?',
      a: [
        "Yes we do but the type of Residents we place in your home won’t need to be evicted. We pride ourselves on finding the best Residents for your home, that will love it as much as you do, which means they will pay their rent. That said, Belong's eviction protection is a valuable safety net for homeowners. We offer coverage of up to $15,000 against legal fees in the event of an eviction. This is a distinctive feature that sets us apart from other property management companies. It provides homeowners with premium financial protection and peace of mind, which may not be available with other services.",
      ],
    },
    {
      q: 'Who are your Pros?',
      a: [
        'Belong has partnered with thousands of licensed, bonded & insured maintenance pros to ensure your home gets the very best quality of work, all at market rates.',
      ],
    },
    {
      q: 'What kind of customer support can I expect from Belong, and how does it compare to the support provided by other property management services?',
      a: [
        'Belong is committed to providing exceptional customer support and experience. We aim to get things done exceptionally well, communicate with you proactively, and make sure that the stress of managing your home is not something you ever have to worry about.',
        'That said when you, your home, or your residents need support, we are available and ready to help. Our 24/7 support team is always available to chat and handle urgent requests via our app, local phone lines or our website.In addition, each homeowner and resident is assigned  a dedicated member success team for concierge level attention and support over the course of your partnership with Belong. . Lastly, when a request is received, our local, boots-on-the-ground team ensures timely responses and assistance.',
        'Ultimately, what you get from Belong is a support structure designed to help you achieve your goals while maintaining your peace of mind. This is unfortunately uncommon in the property management business and is the reason we exist. We believe there is a better way.  ',
      ],
    },
    {
      q: 'Does Belong have a mobile app? What is unique about it compared to competitors?',
      a: [
        "Yes, we have an app for both homeowners & residents! Belong's mobile app is designed with the homeowner’s convenience in mind. It allows you to manage your entire renting experience from anywhere. What sets us apart is our user-friendly interface, comprehensive features, and most importantly our dedication to continuously upgrade and improve the homeowner experience by leveraging technology.",
        'Our app is a level above off the shelf solutions that you will get access to from the competition. We start with the basics - you can easily track rental income, access property documents, request maintenance, and stay updated on home performance—all in one place. In addition, as we continue to build the definitive one-stop-shop experience for all your home management needs, you also have access to new services such as one-click insurance (for homeowners and residents), and the ability to finance any expense (including maintenance and the placement fees).',
        'We are constantly innovating our app to provide you and your residents with the most seamless retinal experience. This level of convenience & innovation distinguishes our app from those offered by competitors.',
      ],
    },
    {
      q: 'Does Belong offer cash flow management and financial solutions?',
      a: [
        'Belong offers unique cash flow management solutions to homeowners.',
        'It starts with our guaranteed rent offering. We are so confident in our resident placement process that we pay you on time, every month, whether or not your residents pay us.',
        'Additionally, we provide homeowners with the option to split the cost of major repairs and our placement fee across a lease period, ensuring you remain cash-flow-positive. Our approach focuses on helping you build wealth over the long term. This feature distinguishes us from other property management services. It offers financial flexibility and helps you maintain a healthy cash flow while keeping your property in top condition. With Belong you do not have to risk your financial freedom while owning a rental home!',
      ],
    },
    {
      q: 'Does Belong provide insurance for landlords?',
      a: [
        'Yes! At Belong we focus on comprehensive solutions for both homeowners and residents. When onboarding with us, you can choose [our in-network insurance option for landlords](/blog/what-type-of-insurance-for-rental-property/). If you already have a different provider, you have the option to continue working with them as well.',
      ],
    },
    {
      q: 'Does Belong have a referral program?',
      a: [
        "Yes, we have an incredible referral program which allows you to earn $1000 for every homeowner you refer that gets their home leased via Belong. This applies to homeowners and residents both in & out of our current network. If you’re interested in learning more, full details can be found on [Belong's referral\u00a0page](/referral/).",
      ],
    },
    {
      q: 'Does Belong have an affiliate program?',
      a: [
        'With Belong’s affiliate program, you get a chance to earn rental income without owning a home. Join Belong’s Affiliate Program to introduce homeowners to a 10x better experience, and earn up to $1,000 per home referred. More information can be found on [Belong’s affiliate program](/affiliates/) page.',
      ],
    },
  ],
  resident_heading: 'Resident',
  resident: [
    {
      q: 'What does Belong Membership mean for me as a Resident?',
      a: [
        'Say goodbye to the ongoing power struggle between you and your Homeowner. No more drama, no more nightmare stories. Our relationship with you is just as important as our relationship with the Homeowner; no more feeling like a second-class citizen.',
        'Belong is here not just to help you find your dream home, in your dream neighborhood; we are here to get you out of the broken rental system and help foster a connection with the people and places that give your new neighborhood its unique character.',
        'We don’t stop there: alongside our Member Service Team, you can access your Belong account online or in the app at anytime. There, you’ll be able to view rent payments and documents, and request maintenance work. Say goodbye to playing phone tag, missing maintenance requests, and absentee property management.',
      ],
    },
    {
      q: 'How long does my Belong Membership last?',
      a: [
        'As long as you’re living in a Belong home (or own one, for that matter), you can take advantage of everything a Belong Membership has to offer. During that time, you’ll accumulate Karma, giving you even greater access and benefits.',
        'Looking to move? Take advantage of early access to listings and instant approvals, without the need for application fees. You’re in!',
      ],
    },
    {
      q: 'How much does Belong cost?',
      a: [
        // TODO: make a global constants file that updates frontend and backend for Application Fee and Application Days
        `As a Belong Member, you will only be responsible for your ${PAYMENTS_FEE_CONST.APPLICATION_FEE_CC} application fee which covers the cost of your background and credit check. And don’t worry about paying repeated fees for different homes, this covers you for all home applications for 60 days.`,
        'Once you are placed in a Belong Home and are in residence for a year, you will not need to pay for additional applications in the future; Belong has your back.',
      ],
    },
    {
      q: 'When can I see the home?',
      a: [
        'Right away! You do not need to complete an application before scheduling a tour with a Resident Onboarding Specialist. On the home listing page, you will be able to select your preferred viewing time. You may view the home with us or on your own with our self-tour feature.',
        'Don’t see a date and time that works for you? [Speak with](/faq?activeModal=CONTACT_US) a member of our team to find a time that works on your schedule.',
        'In a hurry or don’t want to waste time viewing homes that aren’t a fit? Each of our listings includes a full gallery of high-resolution photos, a virtual 3D-walkthrough, and street-views so you can scope out the surroundings of your potential new home.',
      ],
    },
    {
      q: 'How can I learn more about the neighborhood?',
      a: [
        'Not only will we help you find your ideal neighborhood, we will help you connect with your neighbors, find nearby events, and give you insider tips on the best restaurants in town. We aren’t your run-of-the-mill property management company.',
        'We’ll help find a home you’ll love AND a community to be a part of. We’ll provide all the information you need to find the right place for you and your family. Looking for good schools, a walkable downtown, or weekly farmer’s market? We have you covered.',
      ],
    },
    {
      q: 'How do I apply to live in a Belong Home?',
      a: [
        `Apply on our website, directly on the listing page of your desired home. You will need your bank account credentials or income verification documents, a valid form of ID, contact info for your most recent landlord, and you will need to pay the ${PAYMENTS_FEE_CONST.APPLICATION_FEE_CC} application fee. The application fee covers the cost for you background and credit check.`,
        // TODO: make a global constants file that updates frontend and backend for Application Fee and Application Days
        'Interested in applying for multiple homes? Just click apply on each page and you will have the option to use an application you already complete or start a new application. You can apply for an unlimited number of homes  only but will need to pay the application fee once (within a 60-day period).',
      ],
    },
    {
      q: 'What if my application is not approved?',
      a: [
        'You may reapply to the same home with more information or a cosigner if your application is not approved. You may also apply for other homes, having unapproved applications on file will not necessarily hurt your chances of getting approved for another home, depending on the reason.',
      ],
    },
    {
      q: 'How much should I expect to pay to move-in?',
      a: [
        'On each Belong Home listing page, you will find the required deposit for that home. There are no hidden fees or surprise charges.',
      ],
    },
    {
      q: 'What if I need a cosigner?',
      a: [
        'You’ll have the option add a cosigner to your application when verifying your income. [Speak with](/faq?activeModal=CONTACT_US) our Member Services Team to learn more about the application process.',
      ],
    },
    {
      q: 'Are pets allowed?',
      a: [
        'Yes, we’re big fans of our furry friends, and as such, pets are welcome in many of our homes. Each home listed on our site will display which types of pets are allowed. And because we love animals of all shapes and sizes, we promise not to discriminate against certain breeds, unless otherwise specified by your Homeowner’s Association.',
        "Depending on the home, you may be responsible for a separate pet fee or deposit. You can find this on each home's listing on our website.",
      ],
    },
    {
      q: 'Who pays for the cost of utilities such as water, electricity, and gas?',
      a: [
        "Responsibility for utilities varies for each home depending on the market and that home's unique setup. You can find that information on each home's listing on our webiste.",
      ],
    },
    {
      q: 'How quickly can I move in?',
      a: [
        'Move-in dates are listed on each Belong Home listing. If the home you are interested in is already available for move-in, you can take up residence as soon as the paperwork is completed and the deposit is made.',
        'Your Resident Move-In Specialist will coordinate with you or your movers.',
      ],
    },
    {
      q: 'How do I pay rent?',
      a: [
        'No more hassling with sending checks or clunky old systems. Inside the Belong app, you can set up your preferred method of payment, ACH or credit card. We will automatically deduct your rent on the first of each month. You can login to [your account](/accounts) at any time as well to update your preferences.',
      ],
    },
    {
      q: 'How do I request maintenance, home upgrades, or report emergencies?',
      a: [
        'As a Belong Member, you can make and manage requests right from the Belong app. You can also contact your personal Member Success Lead team when you feel like speaking to a real person. With 10,000+ maintenance Pros in our network, we ensure all repairs are completed quickly and done right the first time.',
      ],
    },
    {
      q: 'How do I get my security deposit back?',
      a: [
        "Deposits are required in the event there is major damage to the home. Belong cleans and touches up paint due to normal wear and tear in every home when a Resident such as yourself moves out, so you won't need to sweat the small stuff.",
        'You will receive your security deposit upon moving out of your home, once the move-out inspection is completed. Your security deposit will be deposited back into the bank account linked to your Belong account.',
        'Any damages (in excess of ordinary wear and tear), additional cleaning fees, lease violations (such as needing to move out before the end of your lease), unpaid rent, or charges (such as unpaid utility bills) will be deducted from your deposit. If deductions are made, you will receive an itemized statement detailing how the deposit was applied.',
        'At Belong, we care deeply about our relationship with you; we will do everything in our power to help you receive your full deposit back. No surprises. No games.',
      ],
    },
    {
      q: 'What guarantees do I have as a Resident?',
      a: [
        'As a Belong Resident, you can be certain we will always keep your best interests at heart. We have been Residents ourselves, and know what it feels like to be treated as a second-class citizen. We promise to never do that to you.',
        "If something needs to be fixed, we'll do so in a timely manner. Have an emergency? Our 24/7 emergency team will be there for you to set things right.",
        'It is our job to help you find a home you love, in a neighborhood you love, and provide service you love.',
      ],
    },
    {
      q: 'Does Belong have a referral program?',
      a: [
        "Yes, we have an incredible referral program which allows you to earn $1000 for every homeowner you refer that gets their home leased via Belong. This applies to homeowners and residents both in & out of our current network. If you’re interested in learning more, full details can be found on [Belong's referral\u00a0page](/referral/).",
      ],
    },
    {
      q: 'Does Belong have an affiliate program?',
      a: [
        'With Belong’s affiliate program, you get a chance to earn rental income without owning a home. Join Belong’s Affiliate Program to introduce homeowners to a 10x better experience, and earn up to $1,000 per home referred. More information can be found on [Belong’s affiliate program](/affiliates/) page.',
      ],
    },
  ],
  everyone_heading: 'Everyone',
  everyone: [],
};
